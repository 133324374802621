<form [formGroup]="filterForm">
	<div class="flex gap-x-4 justify-between">
		<!--Select filter-->
		<div class="flex gap-x-4">
			<!--Select filter and sort -->
			<div class="relative min-w-[150px]">
				<div class="relative group">

					<select id="crypto" title="select" formControlName="crypto"
						class="appearance-none px-3 py-2 h-10 text-sm leading-5 font-sans w-full rounded border border-muted-300 bg-white text-muted-600 placeholder-gray-300 focus:border-muted-300 focus:shadow-lg dark:placeholder-gray-600 dark:bg-muted-700 dark:text-muted-200 dark:border-muted-600 dark:focus:border-muted-600 focus:ring-0 outline-transparent focus:outline-dashed focus:outline-gray-300 dark:focus:outline-gray-600 focus:outline-offset-2 transition-all duration-300"
						(change)="filter()">
						<option [value]="'all'">Token</option>
						<option *ngFor="let token of tokens" [value]="token">{{token}}</option>
					</select>

					<div
						class="absolute top-0 right-0 h-10 w-10 flex justify-center items-center text-muted-400 transition-transform duration-300 group-focus-within:-rotate-180 pointer-events-none">
						<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
							stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
							class="h-4 w-4">
							<polyline points="6 9 12 15 18 9"></polyline>
						</svg>
					</div>
				</div>
			</div>
			<!--Select filter-->
			<div class="hidden sm:block relative min-w-[150px]">
				<div class="relative group">
					<select id="markets" (change)="filter()" title="select" formControlName="markets"
						class="appearance-none px-3 py-2 h-10 text-sm leading-5 font-sans w-full rounded border border-muted-300 bg-white text-muted-600 placeholder-gray-300 focus:border-muted-300 focus:shadow-lg dark:placeholder-gray-600 dark:bg-muted-700 dark:text-muted-200 dark:border-muted-600 dark:focus:border-muted-600 focus:ring-0 outline-transparent focus:outline-dashed focus:outline-gray-300 dark:focus:outline-gray-600 focus:outline-offset-2 transition-all duration-300">
						<option [value]="'all'">Market</option>
						<option *ngFor="let market of markets" [value]="market">{{market}}</option>
					</select>
					<div
						class="absolute top-0 right-0 h-10 w-10 flex justify-center items-center text-muted-400 transition-transform duration-300 group-focus-within:-rotate-180 pointer-events-none">
						<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
							stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
							class="h-4 w-4">
							<polyline points="6 9 12 15 18 9"></polyline>
						</svg>
					</div>
				</div>
			</div>
			<!-- <div class="hidden md:block relative min-w-[150px]">
				<div class="relative group">
					<select id="supportedExchanges" title="select" formControlName="supportedExchanges"
						class="appearance-none px-3 py-2 h-10 text-sm leading-5 font-sans w-full rounded border border-muted-300 bg-white text-muted-600 placeholder-gray-300 focus:border-muted-300 focus:shadow-lg dark:placeholder-gray-600 dark:bg-muted-700 dark:text-muted-200 dark:border-muted-600 dark:focus:border-muted-600 focus:ring-0 outline-transparent focus:outline-dashed focus:outline-gray-300 dark:focus:outline-gray-600 focus:outline-offset-2 transition-all duration-300"
						(change)="filter()">
						<option [value]="'all'">Exchanges</option>
						<option *ngFor="let exchange of exchanges" [value]="exchange">{{exchange}}</option>
					</select>
					<div
						class="absolute top-0 right-0 h-10 w-10 flex justify-center items-center text-muted-400 transition-transform duration-300 group-focus-within:-rotate-180 pointer-events-none">
						<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
							stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
							class="h-4 w-4">
							<polyline points="6 9 12 15 18 9"></polyline>
						</svg>
					</div>
				</div>
			</div> -->
			<!--Select filter-->
			<div class="hidden md:block relative min-w-[150px]" *ngIf="providers !== null && providers.length">
				<div class="relative group">
					<select id="publisherName" title="select" formControlName="publisherName"
						class="appearance-none px-3 py-2 h-10 text-sm leading-5 font-sans w-full rounded border border-muted-300 bg-white text-muted-600 placeholder-gray-300 focus:border-muted-300 focus:shadow-lg dark:placeholder-gray-600 dark:bg-muted-700 dark:text-muted-200 dark:border-muted-600 dark:focus:border-muted-600 focus:ring-0 outline-transparent focus:outline-dashed focus:outline-gray-300 dark:focus:outline-gray-600 focus:outline-offset-2 transition-all duration-300"
						(change)="filter()">
						<option [value]="'all'" selected>Provider</option>
						<option *ngFor="let provider of providers" [value]="provider">{{provider}}</option>
					</select>
					<div
						class="absolute top-0 right-0 h-10 w-10 flex justify-center items-center text-muted-400 transition-transform duration-300 group-focus-within:-rotate-180 pointer-events-none">
						<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
							stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
							class="h-4 w-4">
							<polyline points="6 9 12 15 18 9"></polyline>
						</svg>
					</div>
				</div>
			</div>
		</div>
		<!--Select sort-->
		<div class="flex gap-x-4">
			<div class="relative min-w-[150px]">
				<div class="relative group">

					<select title="select" (change)="filter()"
						class="appearance-none px-3 py-2 h-10 text-sm leading-5 font-sans w-full rounded border border-muted-300 bg-white text-muted-600 placeholder-gray-300 focus:border-muted-300 focus:shadow-lg dark:placeholder-gray-600 dark:bg-muted-700 dark:text-muted-200 dark:border-muted-600 dark:focus:border-muted-600 focus:ring-0 outline-transparent focus:outline-dashed focus:outline-gray-300 dark:focus:outline-gray-600 focus:outline-offset-2 transition-all duration-300"
						formControlName="order">
						<option [value]="'recency'">Recent</option>
						<option [value]="'name'">Name</option>
						<option [value]="'weekly'">Weekly performance</option>
						<option [value]="'monthly'">Monthly performance</option>
						<option [value]="'quarterly'">Quarterly performance</option>
						<option [value]="'overall'">Overall performance</option>
					</select>

					<div
						class="absolute top-0 right-0 h-10 w-10 flex justify-center items-center text-muted-400 transition-transform duration-300 group-focus-within:-rotate-180 pointer-events-none">
						<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
							stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
							class="h-4 w-4">
							<polyline points="6 9 12 15 18 9"></polyline>
						</svg>
					</div>

					<div class="hidden sm:block absolute bottom-12">
						<label class="font-sans text-sm inline-block text-muted-500 dark:text-muted-400">Order by</label>
					</div>
				</div>
			</div>
		</div>
	</div>
</form>
