import { Component, OnDestroy, OnInit } from "@angular/core";
import {
	MAX_RETRY_ATTEMPTS,
	RETRY_DELAY,
} from "@app/core/constants/common.constants";
import { TokenContractEvent } from "@app/core/constants/staking.constants";
import {
	PlanSubscriptionErrorCodes,
	TransactionErrorCodes,
} from "@app/core/constants/subscriptions/error-codes";
import {
	PlanSubscriptionErrorMessages,
	SubscriptionRetryErrorMessages,
	TransactionErrorMessages,
} from "@app/core/constants/subscriptions/error-messages";
import { TransactionSuccessMessages } from "@app/core/constants/subscriptions/success-messages";
import { BcubeContractService } from "@app/core/services/bcube-contract.service";
import { CryptoTransactionService } from "@app/core/services/crypto-transaction-service";
import { EthereumService } from "@app/core/services/ethereum/ethereum.service";
import { ExchangeAccountService } from "@app/core/services/exchange-account.service";
import { PlanService } from "@app/core/services/plan.service";
import { StakingService } from "@app/core/services/staking.service";
import { UserService } from "@app/core/services/user.service";
import { TransactionStatus } from "@b-cube/database/types";
import { IPaymentBCubeDetails } from "@b-cube/interfaces/payment";
import {
	Addon,
	ISubscription,
	Plan,
	SubscriptionType,
	TransactionType,
	UNLIMITED_TRADING_LIMIT,
} from "@b-cube/interfaces/plan/index";
import { PaymentMethod, UserInfo } from "@b-cube/interfaces/user";
import { TransactionResponse } from "@ethersproject/abstract-provider";
import { PlanDifference, SPECIAL_VALUES } from "@shared/types/plan-diffrence";
import { DateTime } from "luxon";
import { ToastrService } from "ngx-toastr";
import {
	catchError,
	combineLatest,
	delay,
	lastValueFrom,
	map,
	mergeMap,
	Observable,
	of,
	retry,
	Subscription,
	switchMap,
	tap,
} from "rxjs";

@Component({
	selector: "app-widget-plan-current",
	templateUrl: "./widget-plan-current.component.html",
})
export class WidgetPlanCurrentComponent implements OnInit, OnDestroy {
	// user
	user$: Observable<UserInfo>;
	userTierProgress$: Observable<any>;
	initials$: Observable<string>;

	// current plan
	currentPlan: Plan;
	currentSubscription: ISubscription;
	subscriptionLoaded = false;
	isFreemiumExpired = false;
	currentAddons: Addon[];
	bcubePaymentDetails: IPaymentBCubeDetails;

	// next plan
	planSubscription: Subscription;
	plans$: Observable<Plan[]>;
	nextPlan$: Observable<Plan>;
	nextPlanAvailable$: Observable<boolean>;
	planDifference$: Observable<PlanDifference>;

	// trading volume
	tradingVolume$: Observable<number>;
	tradingPourcent$: Observable<number>;

	//UI
	loaded$: Observable<boolean>;

	UNLIMITED_TRADING_LIMIT = UNLIMITED_TRADING_LIMIT;

	// top up
	isLoadingTopUp = false;
	showTopUpForm = false;
	months = 1;

	private unwatchTokenContractEvent: any;
	private walletAddressSubscription: Subscription;
	public wallet: string;

	constructor(
		private userService: UserService,
		private planService: PlanService,
		private stakingService: StakingService,
		private bcubeContractService: BcubeContractService,
		private toasterService: ToastrService,
		private exchangeAccountService: ExchangeAccountService,
		private ethereumService: EthereumService,
		private cryptoTxService: CryptoTransactionService,
	) {}

	ngOnInit(): void {
		// user
		this.user$ = this.userService.currentUser;
		this.userTierProgress$ = this.userService.getUserPlanProgress();
		this.initials$ = this.userService.getCurrentUserInitials();

		// next plan
		this.plans$ = this.planService.plans;
		this.nextPlan$ = combineLatest([this.user$, this.plans$]).pipe(
			switchMap(([user, plans]) => {
				return of(this.getNextPlan(user?.plan, plans));
			}),
			// retry(),
			catchError((e) => {
				// eslint-disable-next-line
				console.log("ERROR: " + e.message);

				return of(null);
			}),
		);

		this.nextPlanAvailable$ = this.nextPlan$.pipe(
			map((nextPlan) => nextPlan !== null),
		);

		this.planDifference$ = combineLatest([this.user$, this.nextPlan$]).pipe(
			switchMap(([user, nextPlan]) => {
				return of(this.computeDifference(user?.plan, nextPlan));
			}),
			// retry(),
			catchError((e) => {
				// eslint-disable-next-line
				console.log("ERROR: " + e.message);

				return of(null);
			}),
		);

		// trading volume
		this.tradingVolume$ = this.exchangeAccountService.totalInvestment;
		this.tradingPourcent$ = combineLatest([
			this.user$,
			this.tradingVolume$,
		]).pipe(
			map(([user, tradingVolume]) =>
				user === null
					? 0
					: Math.round((100 * tradingVolume) / user.plan.tradingLimit),
			),
			catchError((e) => {
				// eslint-disable-next-line
				console.log("ERROR: " + e.message);

				return of(null);
			}),
		);

		// subscription
		this.planSubscription = combineLatest([
			this.user$,
			this.planService.subscription,
			this.planService.isFreemiumExpired,
		]).subscribe(async ([user, subscription, expired]) => {
			this.currentPlan = user?.plan;
			this.currentSubscription = subscription;
			this.subscriptionLoaded = true;
			this.isFreemiumExpired = expired;
			this.currentAddons = this.getCurrentAddons(user, subscription);
			if (
				!this.currentSubscription ||
				this.currentSubscription.isCreditCard ||
				!this.currentAddons ||
				!user
			) {
				this.bcubePaymentDetails = null;
			} else {
				this.bcubePaymentDetails = await lastValueFrom(
					this.stakingService.getPlanBCubePaymentDetails(
						user.plan.id,
						this.currentAddons.map((addon) => addon.id),
					),
				);
			}
		});

		// UI
		this.loaded$ = combineLatest([this.user$, this.plans$]).pipe(
			switchMap(([user, plans]) => of(user !== null && plans !== null)),
			delay(100),
			catchError((e) => {
				// eslint-disable-next-line
				console.log("ERROR: " + e.message);

				return of(null);
			}),
		);

		this.initWallet();
	}

	ngOnDestroy(): void {
		this.planSubscription.unsubscribe();
		this.walletAddressSubscription.unsubscribe();
	}

	private initWallet(): void {
		this.walletAddressSubscription = this.stakingService
			.getWallet()
			.subscribe(async (wallet) => {
				this.wallet = wallet;
			});
	}

	private getCurrentAddons(
		user: UserInfo,
		subscription: ISubscription,
	): Addon[] {
		if (!user || !subscription) {
			return [];
		}

		return user.plan.addons.filter((addon) => {
			return (
				subscription?.subscriptionItems.find(
					(subItem) => subItem.itemPriceId === addon.itemPriceId,
				) !== undefined
			);
		});
	}

	private getNextPlan(currentPlan: Plan, plans: Plan[]): Plan {
		if (currentPlan === undefined || currentPlan === null) {
			return null;
		}

		return plans.find((plan) => plan.tier === currentPlan.tier + 1) ?? null;
	}

	private computeDifference(currentPlan: Plan, nextPlan: Plan): PlanDifference {
		if (currentPlan === null || nextPlan === null) {
			return null;
		}

		let diffTradingLimit;
		if (nextPlan.tradingLimit === UNLIMITED_TRADING_LIMIT) {
			diffTradingLimit = SPECIAL_VALUES.PLUS_INFINITY;
		} else if (currentPlan.tradingLimit === UNLIMITED_TRADING_LIMIT) {
			diffTradingLimit = SPECIAL_VALUES.MINUS_INFINITY;
		} else {
			diffTradingLimit =
				(nextPlan.tradingLimit - currentPlan.tradingLimit) / 1000;
		}

		return <PlanDifference>{
			tradingLimit: diffTradingLimit,
			price: nextPlan.price - currentPlan.price,
		};
	}

	private async initializeBcubePaymentTransaction(
		amount: number,
	): Promise<TransactionResponse> {
		try {
			return await this.bcubeContractService.sendBCubeToPaymentWallet(amount);
		} catch (error: any) {
			if (error.reason) {
				throw {
					code: TransactionErrorCodes.TRANSACTION_FAILED,
					message: error.reason,
				};
			}
			throw {
				code: TransactionErrorCodes.TRANSACTION_FAILED,
				message:
					TransactionErrorMessages[TransactionErrorCodes.TRANSACTION_FAILED],
			};
		}
	}

	private async createCryptoPendingTransaction(
		tx: TransactionResponse,
		transactionType: TransactionType,
		amount: number,
	): Promise<void> {
		try {
			await this.cryptoTxService.createCryptoTransaction({
				planId: this.currentPlan.id,
				status: TransactionStatus.PENDING,
				paymentMethod: PaymentMethod.BCUBE,
				price: amount,
				months: this.months,
				ethWalletAddress: tx.from,
				ethTransaction: tx.hash,
				addons: [],
				subscriptionType: SubscriptionType.BOT,
				transactionType: transactionType,
			});
		} catch (error: any) {
			throw {
				code: TransactionErrorCodes.TRANSACTION_CREATION_FAILED,
				message:
					TransactionErrorMessages[TransactionErrorCodes.TRANSACTION_FAILED],
			};
		}
	}

	private async fetchUpdatedSubscription(
		expectedMonthsToAdd: number,
		currentBotSubscription: ISubscription,
	): Promise<ISubscription> {
		let currentRetryAttempt = 0;

		return await lastValueFrom(
			this.planService.getSinglePlanSubscription().pipe(
				mergeMap((retrievedSubscription: ISubscription) => {
					const originalEndDate = DateTime.fromMillis(
						currentBotSubscription.currentTermEnd * 1000,
					);
					const updatedEndDate = DateTime.fromMillis(
						retrievedSubscription.currentTermEnd * 1000,
					);
					const anticipatedEndDate = originalEndDate.plus({
						months: expectedMonthsToAdd,
					});

					if (anticipatedEndDate.equals(updatedEndDate)) {
						return of(retrievedSubscription);
					}
					throw new Error(
						SubscriptionRetryErrorMessages.DATE_DIFFERENCE_UNMATCHED,
					);
				}),
				tap({
					error: (_error) => {
						if (currentRetryAttempt < MAX_RETRY_ATTEMPTS) {
							currentRetryAttempt++;
						} else {
							throw new Error(
								SubscriptionRetryErrorMessages.RETRY_LIMIT_EXCEEDED,
							);
						}
					},
				}),
				retry({ count: MAX_RETRY_ATTEMPTS, delay: RETRY_DELAY }),
				catchError((err) => {
					if (
						err.message === SubscriptionRetryErrorMessages.RETRY_LIMIT_EXCEEDED
					) {
						throw {
							code: PlanSubscriptionErrorCodes.PLAN_TOPUP_PENDING_TRANSACTION,
							message:
								PlanSubscriptionErrorMessages[
									PlanSubscriptionErrorCodes.PLAN_TOPUP_PENDING_TRANSACTION
								],
						};
					}
					throw err;
				}),
			),
		);
	}

	private displayPaymentError(error: {
		code: TransactionErrorCodes;
		message: string;
	}): void {
		let errorMessage: string;

		switch (error.code) {
			case TransactionErrorCodes.TRANSACTION_CREATION_FAILED:
			case TransactionErrorCodes.TRANSACTION_FAILED:
				errorMessage = error.message;
				break;

			default:
				errorMessage =
					TransactionErrorMessages[TransactionErrorCodes.TRANSACTION_FAILED];
				break;
		}

		this.toasterService.error(errorMessage);
	}

	public async topUp(): Promise<void> {
		this.isLoadingTopUp = true;
		this.unwatchTokenContractEvent =
			this.bcubeContractService.watchTokenContractEvent(
				TokenContractEvent.TRANSFER,
				this.wallet,
			);
		const finalPrice = this.bcubePaymentDetails.priceDiscount * this.months;

		try {
			const tx = await this.initializeBcubePaymentTransaction(finalPrice);
			await this.createCryptoPendingTransaction(
				tx,
				TransactionType.TOPUP,
				finalPrice,
			);
			await this.bcubeContractService.waitForBCubeToPaymentTransaction(tx);
		} catch (error: any) {
			this.bcubeContractService.closeLoader();
			this.displayPaymentError(error);
			this.isLoadingTopUp = false;

			return;
		} finally {
			this.unwatchTokenContractEvent(); // Unsubscribe from the event
		}

		try {
			const updatedSubscription = await this.fetchUpdatedSubscription(
				this.months,
				this.currentSubscription,
			);

			this.currentSubscription = updatedSubscription;
			this.isLoadingTopUp = false;
			this.showTopUpForm = false;
			this.planService.loadSubscription();
			this.bcubeContractService.closeLoader();
			this.toasterService.success(
				TransactionSuccessMessages.TRANSACTION_SUCCESSFUL,
			);
		} catch (error: any) {
			if (
				error.code === PlanSubscriptionErrorCodes.PLAN_TOPUP_PENDING_TRANSACTION
			) {
				this.toasterService.error(error.message);
			} else {
				this.toasterService.error(
					TransactionErrorMessages[
						TransactionErrorCodes.TRANSACTION_UNEXPECTED_ERROR
					],
				);
			}
			this.bcubeContractService.closeLoader();
			this.isLoadingTopUp = false;
			this.showTopUpForm = false;
		}
	}

	public isPayButtonDisabled(): boolean {
		return !this.ethereumService.isWalletConnected$.value;
	}
}
