<app-screen-loader [showScreenLoader]="showScreenLoader$ | async"></app-screen-loader>
<app-unstake-confirmation-modal (unstakeCustomBcube)="unstakeCustomBcube($event)" [maxUnstakeAmount]="getStakedBcubeBalance()" #modal></app-unstake-confirmation-modal>
<div class="w-full min-h-screen">
	<!--Main wizard-->
	<div *ngIf="currentStep <= 3" class="w-full">
		<div class="mb-5">
			<div class="flex items-center justify-between mb-4">
				<h2 *ngIf="currentStep === 0" class="font-sans text-2xl font-bold text-muted-600 dark:text-white">
					Pickup a tier
				</h2>
				<h2 *ngIf="currentStep === 1" class="font-sans text-2xl font-bold text-muted-600 dark:text-white">
					Your active tier
				</h2>
				<h2 *ngIf="currentStep === 2" class="font-sans text-2xl font-bold text-muted-600 dark:text-white">
					Manage your stakes
				</h2>
		
				<div class="flex gap-4">
					<button *ngIf="currentTier?.tier !== tierLevel.TIER_0" (click)="modal.toggleModal()" class="font-sans text-sm font-semibold text-brand">
						Custom Unstake
					</button>
					<a [routerLink]="'/staking'" class="font-sans text-sm font-semibold text-brand">Cancel</a>
				</div>
			</div>
		</div>

		<div class="w-full flex flex-col md:flex-row gap-6">
			<!--Content-->
			<div class="w-full lg:w-3/4">
				<div
					class="w-full bg-white dark:bg-muted-800 border border-muted-200 dark:border-muted-700 p-8 py-12 rounded-lg">

					<!--Step 1-->
					<div *ngIf="currentStep === 0" class="grid md:grid-cols-2 gap-8 place-items-center">
						<!--Staking tiers-->
						<div class="w-full">
							<div class="w-full max-w-xs space-y-4">
								<!--tier-->
								<div *ngFor="let tier of tiers" class="relative group">
									<input [(ngModel)]="targetTierId" type="radio" name="plan_selection"
										class="peer absolute top-0 left-0 w-full h-full opacity-0 cursor-pointer z-20"
										[value]="tier.id" />
									<div class="absolute top-1/2 right-5 -translate-y-1/2 flex items-center justify-center h-6 w-6 rounded-full border-2 transition-colors duration-300"
										[class]="tier.id === targetTierId ? 'text-brand border-brand' : 'text-transparent border-muted-200 dark:border-muted-700'">
										<span class="block h-2 w-2 rounded-full bg-current"></span>
									</div>
									<div class="flex items-center w-full bg-white dark:bg-muted-800 border p-3 rounded-lg transition-colors duration-300"
										[class]="tier.id === targetTierId ? 'border-brand' : 'border-muted-200 dark:border-muted-700'">
										<div>
											<h3 [innerHTML]="tierNameDisplay(tier)" class="font-sans font-medium text-xl text-brand"></h3>
										</div>
										<div class="ml-auto pr-12 w-[150px]">
											<h3 class="font-sans font-semibold text-xl text-muted-500">
												{{tier.minStakedTokens}}
											</h3>
											<p class="font-sans text-xs text-muted-400">
												{{'BCUBE'}}
											</p>
										</div>
									</div>
								</div>
							</div>
						</div>

						<!--tier details-->
						<div class="my-auto">
							<div class="mb-8">
								<h4 class="font-semibold text-muted-700 dark:text-muted-100 mb-3">
									Tiers details
								</h4>
								<p class="text-sm text-muted-400">
									Compare discounts between tiers
								</p>
								<p class="text-sm text-muted-400 my-2">
									By selecting a different tier, we will stake or unstake the difference between your current tier and the new one.
								</p>
								<p class="text-sm text-muted-400">
									If you choose the Earth tier, all of your tokens will be unstaked.
								</p>
							</div>

							<div class="space-y-3 font-sans">
								<!--Item-->
								<div *ngIf="getTargetTier() !== undefined && getTargetTier().tier !== currentTier.tier" class="flex items-center justify-between">
									<span class="block text-sm text-muted-400 pr-20">Change in staked $BCUBE</span>
									<span *ngIf="getTargetTier() !== undefined"
										class="flex items-center h-8 font-semibold text-muted-600 dark:text-muted-100"><p class="text-sm mt-[-0.2rem]">&nbsp;</p>{{getTokensDifferenceLabel()}}</span>
								</div>
								<!--Item-->
								<div class="flex items-center justify-between">
									<span class="block text-sm text-muted-400 pr-20">Pay plan by Credit Card</span>
									<span *ngIf="getTargetTier() !== undefined"
										class="flex items-center h-8 font-semibold text-muted-600 dark:text-muted-100">{{getTargetTier()?.planDiscountForCC
										/ 100 | percent:'1.0-0'}}</span>
								</div>
								<!--Item-->
								<div class="flex items-center justify-between">
									<span class="block text-sm text-muted-400 pr-20">Pay plan with $BCUBE</span>
									<span *ngIf="getTargetTier() !== undefined"
										class="flex items-center h-8 font-semibold text-muted-600 dark:text-muted-100">{{getTargetTier()?.planDiscountForBCUBE
										/ 100 | percent:'1.0-0'}}</span>
								</div>
								<!--Item-->
								<div class="flex items-center justify-between">
									<span class="block text-sm text-muted-400 pr-20">Pay a 3rd party agent by Credit
										Card</span>
									<span *ngIf="getTargetTier() !== undefined"
										class="flex items-center h-8 font-semibold text-muted-600 dark:text-muted-100">{{getTargetTier()?.botDiscountForCC
										/ 100 | percent:'1.0-0'}}</span>
								</div>
								<!--Item-->
								<div class="flex items-center justify-between">
									<span class="block text-sm text-muted-400 pr-20">Pay a 3rd party agent with
										$BCUBE</span>
									<span *ngIf="getTargetTier() !== undefined"
										class="flex items-center h-8 font-semibold text-muted-600 dark:text-muted-100">{{getTargetTier()?.botDiscountForBCUBE
										/ 100 | percent:'1.0-0'}}</span>
								</div>
							</div>
						</div>
					</div>

					<!--Step 2-->
					<!-- <div *ngIf="currentStep === 1" class="flex items-center flex-col md:flex-row gap-6 md:gap-4 py-8">
						<!--Profile- ->
						<app-widget-wizard-current-tier class="flex-1" [user$]="user$"
							[userTierProgress$]="userTierProgress$" [initials$]="initials$">
						</app-widget-wizard-current-tier>

						<div class="flex-1 h-full px-4">
							<div class="flex items-center justify-center md:justify-start gap-x-2">
								<img src="/assets/img/illustrations/safe-box.png" class="object-contain w-14"
									alt="Illustration" />
								<div>
									<h4 class="text-[0.65rem] font-semibold text-muted-400 uppercase">
										Current tier
									</h4>
									<h3 class="font-sans font-medium text-2xl text-brand capitalize">
										{{(user$ | async)?.tier.name}}
									</h3>
								</div>
							</div>
						</div>

						<!--Next - ->
						<app-widget-wizard-next-tier [nextTier]="getTargetTier()"
							[tierDifference]="getTierDifference()"></app-widget-wizard-next-tier>
					</div> -->

					<!--Step 2-->
					<div *ngIf="currentStep === 1" class="flex items-center flex-col md:flex-row gap-6 md:gap-4 py-8">
						<!--Profile-->
						<app-widget-wizard-current-tier class="flex-1" [user$]="user$"
							[userTierProgress$]="userTierProgress$" [initials$]="initials$">
						</app-widget-wizard-current-tier>

						<!--Stake form-->
						<div class="flex-1">
							<div *ngIf="isWalletConnected$ | async">
								<div
									class="flex w-48 mx-auto md:mx-0 bg-muted-100 dark:bg-muted-700 rounded-lg font-sans mb-4">
									<button class="flex-1 flex items-center justify-center h-10 rounded-lg" [class]="
										shouldStake() ? 'bg-brand text-white': 'bg-transparent text-muted-400'">
										<span class="text-sm">Stake</span>
									</button>
									<button class="flex-1 flex items-center justify-center h-10 rounded-lg" [class]="
										!shouldStake() ? 'bg-brand text-white': 'bg-transparent text-muted-400'">
										<span class="text-sm">Unstake</span>
									</button>
								</div>

								<div class="space-y-5">
									<!--Input-->
									<form [formGroup]="stakingForm">
										<div class="relative">
											<label class="font-alt text-sm text-slate-400">Wallet</label>
											<div class="group relative">
												<input type="text" formControlName="wallet"
													class="px-4 py-3 h-12 text-sm leading-5 font-sans w-full rounded-md bg-white text-slate-600 border border-slate-300 focus:border-slate-300 focus:shadow-lg focus:shadow-gray-300/50 dark:focus:shadow-gray-800/50 placeholder:text-slate-300 dark:placeholder:text-slate-500 dark:bg-slate-700 dark:text-slate-200 dark:border-slate-600 dark:focus:border-slate-600 focus:ring-0 outline-transparent focus:outline-dashed focus:outline-gray-300 dark:focus:outline-gray-600 focus:outline-offset-2 transition-all duration-300"
													placeholder="ex: 3J98t1WpEZ73CNmQviecrnyiWrnqRhWNLy"
													[value]="stakingWallet$ | async" />
											</div>
										</div>
										<!--Input-->
										<div class="relative">
											<label class="font-alt text-sm text-slate-400">Amount</label>
											<div class="group relative">
												<input type="number"
													class="pl-3 pr-20 py-2 h-12 text-sm leading-5 font-sans w-full rounded-md bg-white text-muted-600 border border-muted-300 focus:border-muted-300 focus:shadow-lg focus:shadow-muted-300/50 dark:focus:shadow-muted-800/50 placeholder:text-muted-300 dark:placeholder:text-muted-500 dark:bg-muted-700 dark:text-muted-200 dark:border-muted-600 dark:focus:border-muted-600 focus:ring-0 outline-transparent focus:outline-dashed focus:outline-muted-300 dark:focus:outline-muted-600 focus:outline-offset-2 transition-all duration-300"
													placeholder="B-cube amount" formControlName="amount" />
												<div
													class="absolute top-0 right-0 h-12 w-20 flex items-center justify-center font-sans text-muted-400">
													<span class="text-sm">BCUBE</span>
												</div>
											</div>

											<span *ngIf="(matchingStatus$ | async).networkMatch; else changeNetwork"
												class="font-sans text-xs text-brand">Available: {{ (ownedBcubeBalance$
												| async) | number:'1.0-2' }} BCUBE</span>
											<ng-template #changeNetwork>
												<div
													class="relative z-10 mt-5 flex justify-center items-center border border-orange-500 rounded-md p-5">
													<app-icon-warning
														class="w-8 h-8 text-orange-500"></app-icon-warning>
													<span
														class="block font-sans font-normal ml-2 text-muted-300 text-sm">Please
														connect to the {{
														network }} network</span>
												</div>
											</ng-template>

										</div>
									</form>
								</div>
							</div>
							<app-wallet-modal #modal></app-wallet-modal>
							<div *ngIf="!(isWalletConnected$ | async)" class="flex justify-center">
								<button (click)="modal.toggleModal()"
									class="min-w-[130px] w-full md:w-auto inline-flex items-center justify-center gap-x-2 px-5 rounded-md h-12 border border-muted-200 bg-white hover:bg-muted-100 text-muted-600 dark:border-muted-600 dark:bg-muted-700 dark:hover:bg-muted-600 dark:text-muted-100 transition-all duration-300">
									<span>Connect</span> <app-icon-wallet class="w-4 h-4"></app-icon-wallet>
								</button>
							</div>
						</div>

						<!--Next -->
						<app-widget-wizard-next-tier [nextTier]="getTargetTier()"
							[tierDifference]="getTierDifference()">
						</app-widget-wizard-next-tier>

					</div>
				</div>

				<!-- Footer -->
				<div class="py-4 md:py-6">
					<div
						class="fixed z-30 inset-x-0 bottom-5 p-4 w-[calc(100%_-_2rem)] mx-[1rem] bg-white dark:bg-muted-800 border border-muted-200 dark:border-muted-700 rounded-md md:z-0 md:rounded-none md:border-0 md:w-full md:bg-transparent dark:md:bg-transparent md:p-0 md:static md:mx-0 flex justify-end gap-x-2">
						<button *ngIf="currentStep > 0"
							class="min-w-[130px] w-full md:w-auto inline-flex items-center justify-center gap-x-2 px-5 rounded-md h-12 border border-muted-200 bg-white hover:bg-muted-100 text-muted-600 dark:border-muted-600 dark:bg-muted-700 dark:hover:bg-muted-600 dark:text-muted-100 transition-all duration-300"
							[ngClass]="[isLoadingBack ? 'button-loading' : '']" (click)="decrementStep()">
							<span class="font-sans text-sm">Go Back</span>
						</button>
						<button [disabled]="isButtonDisabled()"
							class="min-w-[130px] w-full md:w-auto  inline-flex items-center justify-center gap-x-2 px-5 rounded-md h-12 text-white hover:shadow-md transition-all duration-300 bg-brand hover:bg-brand/90 hover:shadow-brand/30"
							[ngClass]="[
								isLoadingNext ? 'button-loading' : ''
							]" [ngClass]="{
								'opacity-50 cursor-not-allowed': isButtonDisabled()
							}" (click)="incrementStep()">
							<span class="font-sans text-sm">{{getButtonLabel()}}</span>
						</button>
					</div>
				</div>
			</div>

			<!--Help Area-->
			<div class="w-full md:w-1/4 hidden lg:block">
				<div class="flex flex-col gap-y-6">
					<!--Tips box-->
					<div
						class="w-full bg-muted-50 dark:bg-muted-800 border border-muted-200 dark:border-muted-700 p-6 lg:p-8 rounded-lg">
						<div class="flex items-center gap-x-2 mb-4">
							<div
								class="flex items-center justify-center h-10 w-10 rounded-full bg-secondary-100 dark:bg-secondary-500/20">
								<app-icon-lightbulb class="w-4 h-4 text-secondary-500"></app-icon-lightbulb>
							</div>
							<div class="font-sans">
								<h4 class="font-semibold text-muted-700 dark:text-muted-100">
									Tips
								</h4>
								<p class="text-xs lg:text-sm text-muted-400">Good to know</p>
							</div>
						</div>

						<p class="font-sans text-xs mb-4 text-muted-500 dark:text-muted-400"
							[innerText]="STAKING_TIPS_TEXT">
						</p>
						<a [href]='BUY_BCUBE_URL' target="_blank"
							class="inline-flex items-center justify-between pl-1 pr-4 py-1 border-2 border-brand text-brand hover:text-white font-sans leading-2 text-sm bg-brand/20 hover:bg-brand hover:shadow-md hover:shadow-brand/40 rounded-full cursor-pointer transition-all duration-300">
							<span
								class="flex items-center justify-center h-6 w-6 rounded-full bg-brand text-white rotate-12">
								<app-logo class="w-4 h-4"></app-logo>
							</span>
							<span class="block font-sans font-medium ml-2">Buy</span>
						</a>
					</div>

					<!--Support box-->
					<div
						class="w-full bg-muted-50 dark:bg-muted-800 border border-muted-200 dark:border-muted-700 p-6 lg:p-8 rounded-lg">
						<div class="flex items-center gap-x-2 mb-4">
							<div
								class="flex items-center justify-center h-10 w-10 rounded-full bg-emerald-100 dark:bg-emerald-500/20">
								<app-icon-help class="w-4 h-4 text-emerald-500"></app-icon-help>
							</div>
							<div class="font-sans">
								<h4 class="font-semibold text-muted-700 dark:text-muted-100">
									Help
								</h4>
								<p class="text-xs lg:text-sm text-muted-400">Contact our support team</p>
							</div>
						</div>

						<p class="font-sans text-xs text-muted-500 dark:text-muted-400"
							[innerText]="PLAN_STAKING_HELP_TEXT"></p>
					</div>
				</div>
			</div>
		</div>
	</div>

	<!--Success step-->
	<div *ngIf="currentStep > 3" class="py-12">
		<div class="w-full max-w-lg mx-auto">
			<!--Success card-->
			<div class="w-full bg-white dark:bg-muted-800 border border-muted-200 dark:border-muted-700 p-8 rounded-lg">
				<div class="w-20 mx-auto mb-4">
					<app-icon-check-animated class="text-brand"></app-icon-check-animated>
				</div>
				<div class="text-center mb-4">
					<h2 class="font-sans text-xl font-bold text-muted-600 dark:text-white mb-2">
						Your changes were saved!
					</h2>
					<p class="text-sm text-muted-400 max-w-xs mx-auto">
						Amazing, we saved all the changes you've made to your billing
						settings. You can come back any time to manage your tiers.
					</p>
				</div>

				<a [routerLink]="'/staking'"
					class="flex w-full max-w-[200px] mx-auto items-center justify-center gap-x-2 px-5 rounded-md h-12 text-white hover:shadow-md transition-all duration-300 bg-brand hover:bg-brand/90 hover:shadow-brand/30"
					[ngClass]="[isLoadingNext ? 'button-loading' : '']">
					<span class="font-sans text-sm">Back to staking settings</span>
				</a>
			</div>
		</div>
	</div>
</div>
