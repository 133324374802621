import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BotPlan, ISubscription, Plan } from '@b-cube/interfaces/plan/index';
import { RestURLBuilder } from 'rest-url-builder';
import { BehaviorSubject, Observable, share } from 'rxjs';

import { BOT_PLANS_URL,BOT_SUBSCRIPTION_URL,BOT_SUBSCRIPTIONS_URL,CANCEL_PLAN_URL, PLAN_CHECKOUT, PLAN_URL, SUBSCRIPTION_URL, SUBSCRIPTIONS_URL } from '../constants/api-urls.constants';

@Injectable({
	providedIn: 'root'
})
export class PlanService {
	private readonly plans$: BehaviorSubject<Plan[]> = new BehaviorSubject<
		Plan[]
	>(null);
	private readonly botPlans$: BehaviorSubject<BotPlan[]> = new BehaviorSubject<
		BotPlan[]
	>(null);
	private readonly subscription$: BehaviorSubject<ISubscription> =
		new BehaviorSubject<ISubscription>(null);
	private readonly isFreemiumExpired$: BehaviorSubject<boolean> =
		new BehaviorSubject<boolean>(null);
	private readonly botSubscriptions$: BehaviorSubject<any> =
		new BehaviorSubject<any>(null);
	private subscriptionLoaded = false;

	constructor(private http: HttpClient) {
		this.loadPlans();
		this.loadBotPlans();
	}

	private loadPlans() {
		this.http.get<Plan[]>(PLAN_URL).pipe(
			share(),
		).subscribe(plans => {
			this.plans$.next(plans);
		});
	}

	private loadBotPlans() {
		this.http.get<BotPlan[]>(BOT_PLANS_URL).pipe(
			share(),
		).subscribe(botPlans => {
			this.botPlans$.next(botPlans);
		});
	}

	private listBotSubscriptions() {
		const urlBuilder = new RestURLBuilder();
		urlBuilder.buildRestURL(BOT_SUBSCRIPTIONS_URL);

		return this.http.get<ISubscription[]>(urlBuilder.get()).pipe(
			share(),
		).subscribe(subscriptions => {
			this.botSubscriptions$.next(subscriptions);
		});
	}

	public get plans() : Observable<Plan[]> {
		return this.plans$.asObservable();
	}

	public get botPlans() : Observable<BotPlan[]> {
		return this.botPlans$.asObservable()
	}

	public get botSubscriptions() : Observable<ISubscription[]> {
		this.listBotSubscriptions();

		return this.botSubscriptions$.asObservable()
	}

	public getSingleBotSubscription(botSubscriptionId: string) : Observable<ISubscription> {
		const urlBuilder = new RestURLBuilder();
		urlBuilder.buildRestURL(BOT_SUBSCRIPTION_URL);
		urlBuilder.setNamedParameter('botSubscriptionId', botSubscriptionId);	

		return this.http.get<ISubscription>(urlBuilder.get())
	}

	public getSinglePlanSubscription() : Observable<ISubscription> {
		const urlBuilder = new RestURLBuilder();
		urlBuilder.buildRestURL(SUBSCRIPTION_URL);

		return this.http.get<ISubscription>(urlBuilder.get())
	}

	public addSubscriptionLocally(subscription: ISubscription): Observable<ISubscription[]> {
		this.botSubscriptions$.value.push(subscription);
		this.botSubscriptions$.next(this.botSubscriptions$.value);

		return this.botSubscriptions$.asObservable()
	}

	public loadSubscription() {
		this.http
			.get<ISubscription>(SUBSCRIPTION_URL)
			.pipe(share())
			.subscribe((subscriptionWithExpiration) => {
				const { subscription, isFreemiumExpired } =
					subscriptionWithExpiration as any;
				this.subscription$.next(subscription);
				this.isFreemiumExpired$.next(isFreemiumExpired);
				this.subscriptionLoaded = true;
			});
	}

	public listSubscriptions(subscriptionType: string): Observable<ISubscription[]>{
		const urlBuilder = new RestURLBuilder();
		urlBuilder.buildRestURL(SUBSCRIPTIONS_URL);
		urlBuilder.setQueryParameter('type', subscriptionType);

		return this.http.get<any[]>(urlBuilder.get()).pipe(share());
	}

	public get subscription() : Observable<ISubscription> {
		if(!this.subscriptionLoaded){
			this.loadSubscription();
		}

		return this.subscription$.asObservable();
	}

	public get isFreemiumExpired(): Observable<boolean> {
		if (!this.subscriptionLoaded) {
			this.loadSubscription();
		}
		return this.isFreemiumExpired$.asObservable();
	}

	public getHostedPage(planId: string, addons: string[] = []): Observable<any> {
		const urlBuilder = new RestURLBuilder();
		urlBuilder.buildRestURL(PLAN_CHECKOUT);
		urlBuilder.setNamedParameter('planId', String(planId));
		urlBuilder.setQueryParameter('addons', addons.join(','));

		return this.http.get<any[]>(urlBuilder.get());
	}

	public cancelCreditCardSubscription(botId: number): Observable<any> {
		const urlBuilder = new RestURLBuilder();
		urlBuilder.buildRestURL(CANCEL_PLAN_URL);
		urlBuilder.setQueryParameter('botId', String(botId));

		return this.http.delete<any[]>(urlBuilder.get());
	}
}
