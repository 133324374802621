import { ChangeDetectorRef, Component, Input, NgZone, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { FINAL_STAKING_STEP } from '@app/core/constants/plan.constants';
import { Routes } from '@app/core/constants/router.constants';
import { BUY_BCUBE_URL, MetaMaskErrorCodes, SignatureStatus, STAKING_WARN_MESSAGES,StakingContractEvent } from '@app/core/constants/staking.constants';
import { PLAN_STAKING_HELP_TEXT, STAKING_TIPS_TEXT } from '@app/core/constants/tips.constants';
import { DialogService } from '@app/core/services/dialog.service';
import { EthereumService } from '@app/core/services/ethereum/ethereum.service';
import { UserService } from '@app/core/services/user.service';
import { TierDifference } from '@app/shared/types/tier-difference';
import { Tier, TierLevel, TierName } from '@b-cube/interfaces/staking';
import { UserInfo } from '@b-cube/interfaces/user';
import { BcubeContractService } from '@core/services/bcube-contract.service';
import { StakingService } from '@core/services/staking.service';
import { environment } from '@env/environment';
import { BigNumber } from 'bignumber.js';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, from, map, Observable, Subscription } from 'rxjs';

interface UnstakeEvent {
	eligibleTier: Tier;
	unstakeValue: string;
}

const WIZARD_BUTTONS_LABELS = {
	Continue: 'Continue',
	Confirm: 'Confirm',
	Pay: 'Pay',
	Finish: 'Finish'
}

@Component({
	selector: 'app-widget-staking-wizard',
	templateUrl: './widget-staking-wizard.component.html',
})
export class WidgetStakingWizardComponent implements OnInit, OnDestroy {
	@Input() targetTierId: string = null;
	@Input() currentStep = 0;
	isChecked = true;
	isLoadingBack = false;
	isLoadingNext = false;
	tierLevel = TierLevel

	private chainId = environment.chainId;
	public network: string = environment.networkName;

	// staking form
	stakingForm = new FormGroup({
		wallet: new FormControl(''),
		amount: new FormControl(''),
	});

	public BUY_BCUBE_URL = BUY_BCUBE_URL;

	public STAKING_TIPS_TEXT = STAKING_TIPS_TEXT;
	public PLAN_STAKING_HELP_TEXT = PLAN_STAKING_HELP_TEXT;

	// user
	user$: Observable<UserInfo>;
	userTierProgress$: Observable<any>;
	initials$: Observable<string>;

	private planSubscription: Subscription;
	private bcubeStakeBalanceSubscription: Subscription;

	// tiers
	tiers: Tier[];
	currentTier: Tier;
	tierDifference$: Observable<TierDifference>;

	// ethereum
	public isWalletConnected$: BehaviorSubject<boolean>;

	// staking
	public wallet: string;

	public stakingWallet$: Observable<string>;
	private stakingWalletSubscription: Subscription;

	public matchingStatus$: BehaviorSubject<{ walletsMatch: boolean; networkMatch: boolean }> = new BehaviorSubject<{ walletsMatch: boolean; networkMatch: boolean }>({ walletsMatch: false, networkMatch: false });

	// BCUBE tokens
	public ownedBcubeBalance$: Observable<string>;
	public stakedBcubeBalance$: Observable<string>;
	public differenceBcubeToken$: Observable<number>;

	private stakedBcubeBalance: string;

	// Screen transaction loader
	public showScreenLoader$: BehaviorSubject<boolean>;

	// Watchers
	private unwatchStakingContractEvent: any;
	private unwatchUnstakingContractEvent: any;

	private metamaskNetworkSubscription: Subscription;
	private metamaskWalletSubscription: Subscription;

	constructor(
		private userService: UserService,
		private toasterService: ToastrService,
		private stakingService: StakingService,
		private bcubeContractService: BcubeContractService,
		private ethereumService: EthereumService,
		private router: Router,
		private ngZone: NgZone,
		private cd: ChangeDetectorRef,
		private dialogService: DialogService
	) {
		this.stakingForm.controls.wallet.disable();
		this.stakingForm.controls.amount.disable();
	}

	ngOnInit(): void {
		// user
		this.user$ = this.userService.currentUser;
		this.userTierProgress$ = this.userService.getUserTierProgress();
		this.initials$ = this.userService.getCurrentUserInitials();

		this.isWalletConnected$ = this.ethereumService.isWalletConnected$;
		this.showScreenLoader$ = this.bcubeContractService.showTxLoader$;

		// tiers
		this.stakingService.getTiers().subscribe(tiers => {
			this.tiers = tiers;
		});

		this.planSubscription = this.user$.pipe(map(user => user?.tier)).subscribe(tier => {
			this.currentTier = tier;
			this.targetTierId = tier?.id;
		});

		this.bcubeStakeBalanceSubscription = this.stakingService.getStakedAmount().subscribe(amount => {
			this.stakedBcubeBalance = amount;
		});

		this.stakingWallet$ = this.getWallet();

		this.stakingWalletSubscription = this.stakingService.getStakingWallet().subscribe(wallet => {
			this.wallet = wallet;
			// if user changes his wallet in final step - reset wizard
			if (this.isFinalStep()) {
				this.currentStep = 0;
			}
		});

		// watchers
		this.watchAccount();
		this.watchNetwork();

	}

	ngOnDestroy() {
		this.planSubscription.unsubscribe();
		this.bcubeStakeBalanceSubscription.unsubscribe();
		this.stakingWalletSubscription.unsubscribe();
		this.metamaskWalletSubscription.unsubscribe();
		this.metamaskNetworkSubscription.unsubscribe();
	}

	// Invoke a manual change detection to prevent ExpressionChangedAfterItHasBeenCheckedError. This error can occur
	// when our component's state depends on the value of another component, which may cause inconsistencies after a change detection cycle.
	// Using ChangeDetectorRef.detectChanges() forces an additional check after Angular has finished its initial view check.
	// For more details on the error, visit: https://angular.io/errors/NG0100

	ngAfterViewChecked() {
		this.cd.detectChanges();
	}


	private watchAccount() {
		this.metamaskWalletSubscription = this.ethereumService.currentEthAddress$.subscribe(wallet => {
			const walletsMatch = wallet ? wallet.toLowerCase() === this.wallet?.toLowerCase() : false;
			this.updateMatchingStatus(walletsMatch, this.matchingStatus$.value.networkMatch);
		});
	}

	private watchNetwork() {
		this.metamaskNetworkSubscription = this.ethereumService.currentChainId$.subscribe((chainId) => {
			const networkMatch = chainId ? chainId === this.chainId : false;
			this.updateMatchingStatus(this.matchingStatus$.value.walletsMatch, networkMatch);
		});
	}

	private updateMatchingStatus(walletsMatch: boolean, networkMatch: boolean) {
		this.ngZone.run(() => {
			this.matchingStatus$.next({ walletsMatch, networkMatch });
		});
	}

	public shouldStake(): boolean {
		return Number(this.getTargetTier().minStakedTokens) > Number(this.currentTier.minStakedTokens);
	}

	public async incrementStep(customUnstakeToken:string=null) {
		if (!this.isStepValid()) {
			return;
		}

		// Finish ?
		if (this.isFinalStep()) {
			const amountDiff = customUnstakeToken || this.calculateBcubeTokenDifference();
			try {
				this.shouldStake() ? await this.stakeBcube(amountDiff) : await this.unstakeBcube(amountDiff);
			} catch (error: any) {
				// TODO: handle error SafeERC20: low-level call and etc
				if (error.code === MetaMaskErrorCodes.GAS_LIMIT && await this.handleTokenAllowanceError(amountDiff)) {
					/**
					 * There are several possible reasons for this error:
					 * 
					 * - Insufficient Balance: The address initiating the transaction does not possess enough of the token.
					 * - Insufficient Allowance: If the transaction involves a transferFrom call, the allowance set by the token owner for the spender may be insufficient.
					 * - Smart Contract Revert: The token's smart contract may be reverting the transaction due to another reason. Analyzing the contract's code is necessary to determine the cause.
					 * 
					 *  Now we handle only Insufficient Allowance case.
					 */
					return;
				}

				if(customUnstakeToken) {
					this.currentStep = 0;
				}
				this.toasterService.error('An error occured while processing your transaction');

				return;
			}
			await this.updateCurrentTier();
			this.router.navigate([Routes.STAKING]);

			return;
		}

		this.isLoadingNext = true;
		setTimeout(() => {
			if (this.currentStep < FINAL_STAKING_STEP) {
				this.currentStep = this.currentStep + 1;
				this.isLoadingNext = false;
			}
			// Force change detection after the change detection cycle has completed. (disabled attr in template)
			// https://angular.io/errors/NG0100
			this.cd.detectChanges();
		}, 500);
	}


	async handleTokenAllowanceError(amountDiff: string) {
		const spendingCapAmount = await this.bcubeContractService.getTokenAllowanceAmount(this.wallet);

		if (Number(amountDiff) <= spendingCapAmount) {
			return false;
		}

		const message = STAKING_WARN_MESSAGES.INSUFFICIENT_ALLOWANCE.message.replace('{spendingCapAmount}', spendingCapAmount.toString());
		const shouldProceed = await this.dialogService.confirm(message, STAKING_WARN_MESSAGES.INSUFFICIENT_ALLOWANCE.title);
		if (!shouldProceed) {
			this.router.navigate([Routes.STAKING]);

			return false;
		}
		try {
			await this.bcubeContractService.allowBcubeStake();
			this.toasterService.success(SignatureStatus.SUCCESS);

			return true;
		} catch (error) {
			return false;
		}
	}

	public isFinalStep(): boolean {
		return this.currentStep === FINAL_STAKING_STEP;
	}

	public isButtonDisabled(): boolean {
		const matchingStatus = this.matchingStatus$.value;

		return (!this.isWalletConnected$.value && this.isFinalStep())
			|| (this.isFinalStep() && !matchingStatus.walletsMatch)
			|| (this.isFinalStep() && !matchingStatus.networkMatch);
	}

	private isStepValid(): boolean {
		if (this.currentStep === 0) {
			const targetTier = this.getTargetTier();

			if (!targetTier) {
				this.toasterService.warning('Please select a tier.');

				return false;
			}

			const isSameTier = targetTier.id === this.currentTier.id;

			if (isSameTier) {
				if (this.shouldUnstakeWithoutPlanDowngrade()) {
					return true;
				}
				this.toasterService.warning('Please select a tier different from your current tier.');

				return false;
			}
		}

		return true;
	}

	private async stakeBcube(amount: string) {
		this.unwatchStakingContractEvent = this.bcubeContractService.watchStakingContractEvent(StakingContractEvent.LOAD_BCUBE_STAKING, this.wallet);
		
		try {
			await this.bcubeContractService.stakeBcube(amount);
			this.stakingService.loadStakedAmount();
		} finally {
			this.unwatchStakingContractEvent();
		}
	}

	private async unstakeBcube(amount: string) {
		this.unwatchUnstakingContractEvent = this.bcubeContractService.watchStakingContractEvent(StakingContractEvent.LOAD_BCUBE_UNSTAKING, this.wallet);

		try {
			await this.bcubeContractService.unstakeBcube(amount);
			this.stakingService.loadStakedAmount();
		} finally {
			this.unwatchUnstakingContractEvent();
		}
	}

	private handleWallet(wallet: string): void {
		this.wallet = wallet;
		this.stakingForm.controls.wallet.setValue(wallet);
		this.stakingForm.controls.amount.setValue(this.calculateBcubeTokenDifference());
		const matchingStatus = this.matchingStatus$.value;
		if (matchingStatus.networkMatch) {
			this.ownedBcubeBalance$ = from(this.bcubeContractService.getOwnedBCubeBalance(wallet));
		}
	}

	private getWallet(): Observable<string> {
		return this.stakingService.getStakingWallet().pipe(
			map((wallet: string) => {
				this.handleWallet(wallet);
				const matchingStatus = this.matchingStatus$.value;
				const walletsMatch = wallet.toLowerCase() === this.ethereumService.currentEthAddress$.value.toLowerCase();
				this.matchingStatus$.next({ ...matchingStatus, walletsMatch });

				return wallet;
			})
		);
	}

	public decrementStep() {
		this.isLoadingBack = true;
		setTimeout(() => {
			if (this.currentStep > 0) {
				this.currentStep = this.currentStep - 1;
				this.isLoadingBack = false;
			}
		}, 500);
	}

	public getCurrentTier(): Tier {
		return this.tiers.find(tier => tier.id === this.targetTierId);
	}

	public getTargetTier(): Tier {
		return this.tiers?.find(tier => tier.id === this.targetTierId);;
	}

	public getTierDifference(): TierDifference {
		const targetTier = this.getTargetTier();

		if (targetTier === undefined || this.currentTier === null) {
			return null;
		}

		return <TierDifference>{
			stakedTokens: targetTier.minStakedTokens - this.currentTier.minStakedTokens,
			planDiscountForCC: targetTier.planDiscountForCC - this.currentTier.planDiscountForCC,
			planDiscountForBCUBE: targetTier.planDiscountForBCUBE - this.currentTier.planDiscountForBCUBE,
			botDiscountForCC: targetTier.botDiscountForCC - this.currentTier.botDiscountForCC,
			botDiscountForBCUBE: targetTier.botDiscountForBCUBE - this.currentTier.botDiscountForBCUBE
		}
	}


	private shouldUnstakeWithoutPlanDowngrade(): boolean {
		const isCurrentTierEarth = this.currentTier?.id === TierName.EARTH;
		const isTargetTierEarth = this.getTargetTier()?.id === TierName.EARTH;
		const stakedBcubeDecimal = new BigNumber(this.stakedBcubeBalance);
		const hasStakedBcube = stakedBcubeDecimal.gt(0);

		return isCurrentTierEarth && isTargetTierEarth && hasStakedBcube;
	}


	public calculateBcubeTokenDifference(): string {
		let tokenDifference: BigNumber;
		if (this.shouldUnstakeWithoutPlanDowngrade()) {
			tokenDifference = new BigNumber(this.stakedBcubeBalance);
		} else {
			const targetMinStakedTokens = new BigNumber(this.getTargetTier()?.minStakedTokens);
			const stakedBcubeBalanceDecimal = new BigNumber(this.stakedBcubeBalance);
			const difference = targetMinStakedTokens.minus(stakedBcubeBalanceDecimal);
			tokenDifference = difference.abs();
		}

		return tokenDifference.toFixed();
	}

	private async updateCurrentTier() {
		const targetTier = this.getTargetTier();
		await this.userService.updateTier(targetTier);
	}

	public getButtonLabel(): string {
		if (!this.isFinalStep()) {
			return WIZARD_BUTTONS_LABELS.Continue;
		}

		return WIZARD_BUTTONS_LABELS.Finish;
	}

	tierNameDisplay(tier: Tier): string {
    if (tier.tier === TierLevel.TIER_0 && this.currentTier?.tier !== TierLevel.TIER_0) {
			return `Unstake all<br>(${tier.name})`
		}

		return tier.name
	}

	unstakeCustomBcube(event: UnstakeEvent): void {
		this.targetTierId = event.eligibleTier.id
		this.currentStep = FINAL_STAKING_STEP
		this.incrementStep(event.unstakeValue)
	}

	getStakedBcubeBalance():number{
		return Number(this.stakedBcubeBalance)
	}

	getTokensDifferenceLabel(): string {
		let label = '';
		if (this.getTierDifference()?.stakedTokens < 0) {
			label += '-';
		}
		label += this.calculateBcubeTokenDifference() ?? 0;

		return label;
	}
}
