import { animate, style, transition, trigger } from '@angular/animations';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgModel } from '@angular/forms';
import { UserService } from '@app/core/services/user.service';
import { TierDifference } from '@app/shared/types/tier-difference';
import { Tier } from '@b-cube/interfaces/staking';
import { UserInfo } from '@b-cube/interfaces/user';
import { StakingService } from '@core/services/staking.service';
import { Observable } from 'rxjs';

interface UnstakeEvent {
  eligibleTier: Tier;
  unstakeValue: string;
}

@Component({
	selector: 'app-unstake-confirmation-modal',
	templateUrl: './unstake-confirmation-modal.component.html',
	styleUrls: ['./unstake-confirmation-modal.component.css'],
	animations: [
		trigger('opacity', [
			transition(':enter', [style({ opacity: 0 }), animate('300ms ease-out', style({ opacity: 1 }))]),
			transition(':leave', [style({ opacity: 1 }), animate('200ms ease-in', style({ opacity: 0 }))]),
		]),
		trigger('opacityTranslateY', [
			transition(':enter', [
				style({ opacity: 0, transform: 'translateY(1rem)' }),
				animate('300ms ease-out', style({ opacity: 1, transform: 'translateY(0)' })),
			]),
			transition(':leave', [
				style({ opacity: 1, transform: 'translateY(0)' }),
				animate('200ms ease-in', style({ opacity: 0, transform: 'translateY(1rem)' })),
			]),
		]),
	],
})
export class UnstakeConformationModalComponent implements OnInit {
	@Input() showModal = false;
	@Output() unstakeCustomBcube: EventEmitter<UnstakeEvent> = new EventEmitter<UnstakeEvent>();
	@Input() maxUnstakeAmount: number;

	user$: Observable<UserInfo>;
	
	tierDifference: TierDifference;
	floor = 0;
	unstakeModalStep = 1;
	unstakeValue: number;
	tiers: Tier[];
	eligibleTier: Tier;
	currentTier: Tier;

	@ViewChild('unstakeValueInputField') unstakeValueInputField: NgModel;
	
	constructor(private stakingService: StakingService, private userService: UserService, private cdr: ChangeDetectorRef) {}
	
	ngOnInit() {
		
		this.stakingService.getTiers().subscribe((tiers) => {
			this.tiers = tiers;
		});
		
		this.user$ = this.userService.currentUser;
		this.user$.subscribe((user)=> {
			if (user) {
				this.currentTier = user.tier
				this.setUnstakeParams(this.maxUnstakeAmount / 2);
			}
		})
	}

	setUnstakeParams(value:number): void {
		this.unstakeValue = value;
		console.log(this.unstakeValue)
		this.calculateEligibleTier()
	}

  onUnstakeValueInputChange(value: number): void {
		console.log('change detected', this.unstakeValue, value)
		this.calculateEligibleTier();

    if (value < 0 || value === null || isNaN(value)) {
      this.forceUpdateInputValue(0)
    } else if (value > this.maxUnstakeAmount) {
			this.forceUpdateInputValue(this.maxUnstakeAmount);
    }
  }
	
	forceUpdateInputValue(value:number): void {
		// have to manually force update the field because updating unstakeAmount is not reflected in the UI
		this.unstakeValueInputField.control.setValue(value);
  }

	calculateEligibleTier(): void {
		const tokens = this.maxUnstakeAmount - this.unstakeValue;
		this.eligibleTier = this.getTier(tokens);
		this.tierDifference = this.computeDifference()
	}

	getTier(tokens: number): Tier {
		for (let i = this.tiers.length - 1; i >= 0; i--) {
			if (tokens >= Number(this.tiers[i].minStakedTokens)) {
				return this.tiers[i];
			}
		}

		return this.tiers[0];
	}

	updateStep(value: number): void {
		this.unstakeModalStep += value;
	}

	toggleModal() {
		this.setUnstakeParams(this.maxUnstakeAmount / 2);
		this.unstakeModalStep = 1;
		this.showModal = !this.showModal;
	}

	closeModal(): void {
		this.showModal = false;
	}

	executeUnstake(eligibleTier:Tier, unstakeValue:number) {
		this.unstakeCustomBcube.emit({ eligibleTier, unstakeValue: unstakeValue.toString() });
		this.toggleModal();
		this.unstakeModalStep = 1;
	}

	private computeDifference() {
		if (!this.currentTier || !this.eligibleTier) return null;

		return <TierDifference>{
			stakedTokens:  this.eligibleTier.minStakedTokens - this.currentTier.minStakedTokens,
			planDiscountForCC:  this.eligibleTier.botDiscountForCC - this.currentTier.botDiscountForCC,
			planDiscountForBCUBE:  this.eligibleTier.planDiscountForBCUBE - this.currentTier.planDiscountForBCUBE,
			botDiscountForCC:  this.eligibleTier.botDiscountForCC - this.currentTier.botDiscountForCC,
			botDiscountForBCUBE:  this.eligibleTier.botDiscountForBCUBE - this.currentTier.botDiscountForBCUBE,
		};
	}
}
